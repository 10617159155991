


























































































































import { vxm } from "@/store";
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import {
  AddressDTO,
  CaseApiFactory,
  CaseDTO,
  CaseType,
  CompanyDTO,
  Configuration,
  ContactType, ErpcDTO, UpdateBranchDTO
} from "@shared_vue/openapi/caseapi";
import { OptionDTO } from "@shared_vue/openapi/myvaultapi";
import TbButton from "@/views/TbButton.vue";
import MiniMap from "@/views/viewcomponents/MiniMap.vue";
import AddAddressModal from "@shared_vue/components/AddAddressModal.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ErrorModal from "@/views/widgets/modals/ErrorModal.vue";

@Component({
  components: {
    TbButton,
    MiniMap,
    AddAddressModal,
    WaitModal,
    ErrorModal
  },
})
export default class Step1 extends Vue {
  @Prop() readonly person: ProfileDTO | undefined;
  @Prop() realCase: CaseDTO | undefined;
  @Prop() readonly caseCategory: CaseType[] | undefined;
  @Prop() readonly gotoStep: Function | undefined;

  selectedPrimaryCategory: string = '';
  selectedSecondaryCategory: number = 0;
  private showWait: boolean = false;
  private showError: boolean = false;
  private ui = vxm.ui;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private selectedCategory: any = 0;
  private auth = vxm.auth;
  private addAddressModal: boolean = false;
  private companyList: CompanyDTO[] = [];
  private branchList: ErpcDTO[] = [];
  private dontreset: boolean = false;
  private loadingData=true;
  private firstLoad = true;
  private caseApi = CaseApiFactory(<Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL }, process.env.VUE_APP_CASE_API_URL)
  get uniqueHeaders (): string[] {

    if (this.caseCategory) {
      return Array.from(new Set(this.caseCategory.map(ct => ct.header)));
    } else {
      return [];
    }
  }

  get companyOptions(){
    return this.companyList.map(o=>({value:o.id,label:o.name}));
  }

  get branchOptions(){
    return this.branchList?.filter(b=>b.companyId==this.selectedCompany).map(o=>({value:o.id,label:o.branchName}))??[];
  }

  private mapCompanyDataToIdName(companyList: Array<CompanyDTO>): {id:number,name:string}[] {
    return companyList.map(c=>({id:c.id,name:c.name}));
  }
  private async getCompanies(){
    let allCompanies = await this.caseApi.caseGetCompanies();
    let allBranches = await this.caseApi.caseGetBranches();
    this.companyList = [{id:0,name:'PLEASE SELECT'}].concat(this.mapCompanyDataToIdName(allCompanies.data));
    this.branchList = allBranches.data;
    this.loadingData=false;
  }

  private async saveERPC(){
    console.log('saving ERPC...')
    
    this.realCase!.currentBranchId = this.selectedBranch;
    let result = await this.caseApi.caseUpdateCase(this.realCase!);
    if (result.status!=200){
      console.log('ERROR SAVING ERPC')
    }
    console.log('done!');
    
  }
  
  get selectedCompany() :number {
    if (this.realCase?.company){
      return this.realCase?.company.companyId;
    } else {
      return 0;
    }
  }

  set selectedCompany(toWhat: number){
    console.log('set selected company')
    if (this.realCase?.company === undefined) {
      this.realCase!.company = <ErpcDTO> {}
    }
    this.realCase!.company.companyId = toWhat;

  }

  @Watch('selectedCompany')
  onCompany(val: number, oldval: number) {
    console.log('onCompany')
    if (val!=oldval){ //when changing company dropdown, the branch value does not auto-update. idk why
      console.log(this.$refs.branchselect)
    }
  }

  @Watch('branchOptions')
  onBranchFilter(val: [], oldval: []) {
    console.log('onbranchfilter')
    if (this.loadingData) {
      return;
    }
    if (val!=oldval){
      // @ts-ignore
      // this.$refs.branchselect.value=val[0].value;

      if (val&&val.length>0) {
        
        if (!this.firstLoad) {
          console.log('reset branch')
          // @ts-ignore
          this.selectedBranch = val[0].value;
        } else {
          console.log('do not reset branch')
          this.selectedBranch = this.realCase!.currentBranchId??0;
          this.firstLoad = false;
        }
      }
    }
  }

  get selectedBranch() :number {
    if (this.realCase?.company) {
      return this.realCase!.company!.branchId!;
    } else {
      return 0;
    }
  }

  set selectedBranch(toWhat: number){
    console.log('selectedbranch')
    if (!this.realCase!.company) {
      this.realCase!.company = <ErpcDTO> {}
    }
    this.realCase!.company.branchId = toWhat;
  }

  get primaryCategoryOptions() {
    // Convert uniqueHeaders to the format expected by CSelect
    return this.uniqueHeaders.map(header => ({ value: header, label: header }));
  }
  
  mounted(){
    this.loadingData=true;
    this.getCompanies();
  }

  get filteredSecondaryCategories() {
    // Filter the caseTypes based on the selected primary category
    console.log('filter')
    if (this.caseCategory) {
      return this.caseCategory
          .filter(ct => ct.header === this.selectedPrimaryCategory)
          .map(ct => ({value: ct.uid, label: ct.description}));
    } else {
      return [];
    }
  }

  @Watch('realCase')
  @Watch('caseCategory')
  onCaseOrCategoryChanged() {
    console.log('oncaseOrCat');
    if (this.realCase &&this.realCase.caseTypeId && this.caseCategory && this.caseCategory.length > 0 && !this.dontreset) {
      const actualCase = this.caseCategory.find(c=>c.uid==this.realCase?.caseTypeId);
      console.log('log it')
      if (actualCase) {
        this.toggleWatcher(true);
        this.setSelectedDropdownValues(actualCase);
      }
    }
    console.log('oncaseorcatfinished')
  }

  toggleWatcher(active: boolean) {
    // Vue.nextTick(() => {
      this.dontreset = active;
    // });
  }

  @Watch('selectedPrimaryCategory')
  onPrimaryCategoryChanged(newVal: string, oldVal: string) {
    console.log('onprimary')
    // Reset the selected secondary category when the primary category changes
    console.log('reset secondary')
    const trig = this.filteredSecondaryCategories;
    if (!this.dontreset) {
      this.selectedSecondaryCategory = 0;
    } else {
      console.log('no dont')
    }
  }
  
  @Watch('selectedSecondaryCategory')
  onSecondayCategoryChanged(newVal: string, oldVal: string){
    console.log('new secondary value is ' + newVal)
    if (newVal && newVal!=='' && !this.dontreset) {
      console.log('save secondary')
      this.saveNewCaseCategory(parseInt(newVal));
    }
    if (this.dontreset){
      console.log('clear reset')
      this.toggleWatcher(false);
    }
  }

  setSelectedDropdownValues(caseType: CaseType) {
    console.log('debug here')
    // Set the primary category
    this.selectedPrimaryCategory = caseType.header;

    // Set the secondary category
    this.selectedSecondaryCategory = caseType.uid;
  }
  
  // get DisplayCategoryTypes() {
  //   return this.caseCategory!.map((o) => ({ value: o.id, label: o.text }));
  // }

  private saveNewCaseAddress(newad: AddressDTO){
    console.log('savew new address: ' + JSON.stringify(newad));
    this.addAddressModal=false;
    this.showWait=true;
    try {
      const caseData = this.caseApi.caseAddAddressToCase(this.realCase!.id!, newad);
      this.$emit('updated', caseData);
    } catch (err) {
      this.showError=true;
    }
    finally {
      this.showWait=false;
    }
  }
  
  private saveNewCaseCategory(caseCat: number){
    console.log('savew new cat');
    this.showWait=true;
    try {
      const caseData = this.caseApi.caseSaveCaseCategory(this.realCase!.id!, caseCat )
    } catch (err) {
      this.showError=true;
    }
    finally {
      this.showWait=false;
    }
  }

  private get caseLocation() {
    return {
      latitude: this.realCase?.latitude?parseFloat(this.realCase?.latitude):0,
      longitude: this.realCase?.longitude?parseFloat(this.realCase?.longitude):0,
      infotext:'location'
    }
  }

  get name() {
    return this.auth.usr?.displayName;
  }

  get homeAddressString() {
    return this.getCompactAddress(this.homeAddress);
  }

  get workAddressString() {
    return this.getCompactAddress(this.workAddress);
  }

  private getCompactAddress(address: AddressDTO | undefined): string {
    if (address) {
      return `${address.streetName}, ${address.suburb}, ${address.city}, ${address.postalCode}, ${address.country}`
    }
    return "";
  }

  get homeAddress() {
    return this.selfContact?.addresses?.find(add => add.addressTypeId === 'Primary');
  }

  get selfContact() {
    return this.self?.contacts?.find(contact =>
      contact.contactType.includes(ContactType.Self)
    )
  }

  get emergencyContacts() {
    return this.self?.contacts?.filter(contact =>
      (contact.contactType.includes(ContactType.Emergency1) || (contact.contactType.includes(ContactType.Emergency2)))
    )
  }

  get spouseContact() {
    return this.self?.contacts?.find(contact =>
      contact.contactType.includes(ContactType.Spouse)
    )
  }

  get self() {
    return this.realCase?.caseComplainant;
  }

  get complainantName() {
    const firstName = this.realCase?.caseComplainant?.firstNames || "Name";
    const lastName = this.realCase?.caseComplainant?.lastName || "Lastname";
    console.log(this.realCase);
    return firstName + ' ' + lastName;
  }

  get mobileNumber() {
    return this.realCase?.caseComplainant?.mainCell ?? "N/A";
  }

  get homeNumber() {
    return this.selfContact?.phoneAlternate ?? "N/A";
  }

  get emailAddress() {
    return this.realCase?.caseComplainant?.email;
  }


  get workAddress() {
    return this.selfContact?.addresses?.find(add => add.addressTypeId === 'Work');
  }

  dial(number: string) {
    alert(number);
  }

  email(email: string) {
    alert('Send email to: ' + email);
  }
}
